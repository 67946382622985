@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('@/public/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url('@/public/fonts/Roboto-Thin.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('@/public/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('@/public/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('@/public/fonts/Montserrat-Bold.ttf');
}

@-webkit-keyframes pulseShadow {
  to {
    box-shadow: 0 0 0 15px rgba(239, 104, 163, 0);
  }
}
@-moz-keyframes pulseShadow {
  to {
    box-shadow: 0 0 0 15px rgba(239, 104, 163, 0);
  }
}
@-ms-keyframes pulseShadow {
  to {
    box-shadow: 0 0 0 15px rgba(239, 104, 163, 0);
  }
}
@keyframes pulseShadow {
  to {
    box-shadow: 0 0 0 15px rgba(239, 104, 163, 0);
  }
}

html,
body {
  background-color: #16171b;
  color: #f9faff;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.illustration-bg {
  background-image: url('@/public/img/illustration-bg.svg');
  background-repeat: no-repeat;
  background-size: 80vh;
  background-position: top right;
}

.loading {
  background-image: url('@/public/img/spinner.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

video {
  cursor: pointer;
}

.accent-pink {
  box-shadow: 0 0 0 0 rgba(239, 104, 163, 0.7);
  -webkit-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.accent-green {
  box-shadow: 0 0 0 0 rgba(53, 234, 148, 0.7);
  -webkit-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.accent {
  -webkit-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulsar > .react-switch-handle {
  box-shadow: 0 0 0 0 rgba(63, 222, 255, 0.7);
  -webkit-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulseShadow 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

/* Navigation */

.navigation {
  position: fixed;
  left: 0;
  width: 0;
  height: 100vh;
  z-index: 100;
}

.navigation ul {
  height: 100%;
  background-color: #16171b;
  list-style: none;
  position: absolute;
  top: 0;
  left: -320px;
  transition: left 0.3s;
}

.navigation .menu li.title-menu {
  padding: 20px;
  background: #5f6f81;
  color: #fff;
  text-align: center;
  font-size: 22px;
}

.navigation .menu a {
  display: block;
  padding: 20px;
  border-bottom: 1px solid #434343;
  font-size: 22px;
  font-weight: 200;
  text-decoration: none;
  color: #e6e6e7;
}

.navigation .menu a:hover {
  background: #798da3;
  color: #fff;
}

.navigation .menu li span.icon-menu {
  margin-right: 12px;
}

.navigation .menu .item-submenu > a::after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f105';

  float: right;
  color: #c5c5c5;
}

/* Submenu ============*/

.navigation .submenu li.title-menu {
  background: #fff;
  color: #575d69;
}

.navigation .submenu li.go-back {
  padding: 10px 20px;
  background: #5f6f81;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.navigation .submenu li.go-back::before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f0d9';
  margin-right: 10px;
}

.nav-bottom {
  position: relative;
}

td {
  border: inherit;
  padding: 2px 4px !important;
  font-size: 10px;
  font-weight: 100;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.9) !important;
}

tr {
  border-color: #2b3553;
}

tr:last-child {
  border: none !important;
}

button:focus {
  box-shadow: none !important;
}

th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  color: rgba(255, 255, 255, 0.7);
}

/* empty slider */
.zero::-webkit-slider-runnable-track:not(disabled) {
  background: #2b3553 !important;
}
.zero::-moz-range-track:not(disabled) {
  background: #2b3553 !important;
}
.zero::-ms-fill-lower:not(disabled) {
  background: #2b3553 !important;
}
.zero::-ms-fill-upper:not(disabled) {
  background: #2b3553 !important;
}
.zero:focus::-webkit-slider-runnable-track:not(disabled) {
  background: #2b3553 !important;
}
.zero:focus::-ms-fill-lower:not(disabled) {
  background: #2b3553 !important;
}
.zero:focus::-ms-fill-upper:not(disabled) {
  background: #2b3553 !important;
}
.zero::-webkit-slider-thumb:not(disabled) {
  background: #e14eca !important;
}
.zero::-moz-range-thumb:not(disabled) {
  background: #e14eca !important;
}
.zero::-ms-thumb:not(disabled) {
  background: #e14eca !important;
}

/* disabled slider */
input[type='range']:disabled::-webkit-slider-runnable-track {
  background: #727799 !important;
}
input[type='range']:disabled::-moz-range-track {
  background: #727799 !important;
}
input[type='range']:disabled::-ms-fill-lower {
  background: #727799 !important;
}
input[type='range']:disabled::-ms-fill-upper {
  background: #727799 !important;
}
input[type='range']:disabled::-webkit-slider-thumb {
  background: #cd9ec6 !important;
}
input[type='range']:disabled::-moz-range-thumb {
  background: #cd9ec6 !important;
}
input[type='range']:disabled::-ms-thumb {
  background: #cd9ec6 !important;
}

.slider {
  width: 100%;
  margin: 3px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-runnable-track {
  background: #35ea94;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
.slider::-webkit-slider-thumb {
  margin-top: -5px;
  width: 16px;
  height: 16px;
  background: #e14eca;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
.slider:focus::-webkit-slider-runnable-track {
  background: #60e9a5;
}
.slider::-moz-range-track {
  background: #35ea94;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: rgba(33, 200, 121, 0.93);
  border: 0;
  border-radius: 50px;
  cursor: pointer;
}
.slider::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 3px 0;
  color: transparent;
  width: 100%;
  height: 10px;
  cursor: pointer;
}
.slider::-ms-fill-lower {
  background: #13ba6b;
  border: 0;
  border-radius: 50px;
}
.slider::-ms-fill-upper {
  background: #35ea94;
  border: 0;
  border-radius: 50px;
}
.slider::-ms-thumb {
  width: 16px;
  height: 16px;
  background: rgba(33, 200, 121, 0.93);
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.slider:focus::-ms-fill-lower {
  background: #35ea94;
}
.slider:focus::-ms-fill-upper {
  background: #7ff2bb;
}

/* React tags */
.tag-wrapper {
  display: inline-block;
  border: 1px solid #35ea94;
  box-shadow: 0 0 3px #35ea94;
  -moz-box-shadow: 0 0 3px #35ea94;
  -webkit-box-shadow: 0 0 3px #35ea94;
  border-radius: 4px;
  padding: 4px 8px;
  min-width: 58px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 300;
}

.tag-wrapper > button {
  display: inline-block;
  margin-left: 10px;
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #35ea94;
}

.react-tags-wrapper input {
  height: 45px !important;
  width: 100%;
  background-color: #27293d;
  border: 1px #2b3553 solid;
  color: #ffffff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 300;
  padding: 0.375rem 0.75rem;
}

.react-tags-wrapper input:focus {
  border: 1px solid #35ea94;
  box-shadow: 0 0 3px #35ea94;
  -moz-box-shadow: 0 0 3px #35ea94;
  -webkit-box-shadow: 0 0 3px #35ea94;
  background-color: #27293d;
  color: #ffffff;
  outline: none !important;
}

.react-tags-wrapper input[type='text'].invalid {
  border-color: #dc3545 !important;
  box-shadow: 0 0 3px #dc3545 !important;
  -moz-box-shadow: 0 0 3px #dc3545 !important;
  -webkit-box-shadow: 0 0 3px #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.react-tags-wrapper input[type='text']:hover {
  background-color: #27293d;
  color: #ffffff;
}

/* Reactstrap Datepicker */
.rdp-form-control {
  height: 45px !important;
  width: 100%;
  background-color: #27293d;
  border: 1px #2b3553 solid;
  color: #ffffff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 300;
  padding: 0.375rem 0.75rem;
}

.rdp-form-control:focus {
  border: 1px solid #35ea94;
  box-shadow: 0 0 3px #35ea94;
  -moz-box-shadow: 0 0 3px #35ea94;
  -webkit-box-shadow: 0 0 3px #35ea94;
  background-color: #27293d;
  color: #ffffff;
  outline: none !important;
}

.rdp-addon span {
  background-color: #27293d;
  height: 45px !important;
  color: #35ea94;
  outline: none !important;
  border: none;
  font-size: 22px;
}

.popover-inner {
  background-color: #333950;
}

.popover-header {
  background-color: #262b3b;
}

.text-muted {
  color: #a1b9d9 !important;
}

.text-primary {
  color: #35ea94 !important;
}

.bg-primary {
  background-color: #1fa665 !important;
}

@media screen and (max-width: 320px) {
  .navigation ul {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .nav-link {
    text-align: center;
  }
}
